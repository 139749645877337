import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
import imgSrc from "../assets/maybe-ts__ha.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Slides`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://maybe-ts.now.sh/"
      }}>{`https://maybe-ts.now.sh/`}</a>{` `}<br parentName="p"></br>{`
`}<strong parentName="p">{`The library`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://github.com/hasparus/maybe-ts"
      }}>{`https://github.com/hasparus/maybe-ts`}</a>{` `}<br parentName="p"></br>{`
`}<strong parentName="p">{`Talk duration`}</strong>{`: 15 minutes`}</p>
    <hr></hr>
    <p>{`I spoke about problems with hard Option type (like the one from fp-ts)
mentioned in Rich Hickey’s `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=YR5WdGrpoug"
      }}>{`“Maybe Not”`}</a>{` and presented a foldable
traversable monad instance which can used instead of Option to avoid the
problems mentioned by Rich.`}</p>
    <p><strong parentName="p">{`Edit: It breaks composition law :(`}</strong></p>
    <p>{`Thanks to `}<a parentName="p" {...{
        "href": "https://oliverjash.me/"
      }}>{`@oliverjash`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://functionalprogramming.slack.com/"
      }}>{`FP Slack`}</a>{` I learned and `}<a parentName="p" {...{
        "href": "https://dev.to/gcanti/"
      }}>{`@gcanti`}</a>{` released
the version 0.2.1 of fp-ts-laws 😅.`}</p>
    <p>{`A functor is a mapping between categories. gcanti’s `}<a parentName="p" {...{
        "href": "https://dev.to/gcanti/getting-started-with-fp-ts-category-4c9a"
      }}>{`post on dev.to`}</a>{`
excellently explains what a category is.`}</p>
    <p>{`An endofunctor is a functor which maps a category into itself 🔄`}</p>
    <p>{`An endofunctor in TypeScript is a type `}<inlineCode parentName="p">{`F<A>`}</inlineCode>{` with a `}<inlineCode parentName="p">{`map`}</inlineCode>{` function of type
`}<inlineCode parentName="p">{`<A, B>(fa: F<A>, f: (a: A) => B) => F<B>`}</inlineCode>{`.`}</p>
    <p>{`We’re pretty used to how `}<inlineCode parentName="p">{`map`}</inlineCode>{` works for the most popular functor, the
Array. It would be cool if the definition of Functor was limited to the
types where `}<inlineCode parentName="p">{`map`}</inlineCode>{` behaves similarly. It is. These are the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Functor#Definition"
      }}>{`laws`}</a>{`:`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`1. functors preserve identity:`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`   F.map(fa, a => a) = fa`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`2. functors preserve composition:`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`   F.map(fa, a => bc(ab(a))) = F.map(F.map(fa, ab), bc)`}</span></code></pre>
    <p>{`Our type is defined as`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Maybe<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`null`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`undefined`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`Since `}<inlineCode parentName="p">{`Maybe<Maybe<T>>`}</inlineCode>{` collapses to `}<inlineCode parentName="p">{`Maybe<T>`}</inlineCode>{`, we do not preserve
composition. It is not a functor over nullables. Similar scenario takes
place for `}<inlineCode parentName="p">{`Promise`}</inlineCode>{` and thenables.`}</p>
    <h4 {...{
      "id": "counterexample",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#counterexample",
        "aria-label": "counterexample permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Counterexample`}</h4>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`f`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`null`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(x`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` ? `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`length`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` : `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`);`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`g`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`y`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`number`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(y`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` === `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`10`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` ? `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`null`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` : `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(y`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` * `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`));`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`map`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`10`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`f`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`g`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(x)));`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`right`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`map`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`map`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`10`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`g)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`f);`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk5"
          }}>{`console`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`log`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(left, right); `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// 0 null`}</span></span></code></pre>
    <h4 {...{
      "id": "can-we-save-it",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#can-we-save-it",
        "aria-label": "can we save it permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Can we save it?`}</h4>
    <p>{`But hey! I don’t really need `}<inlineCode parentName="p">{`Maybe<null | undefined>`}</inlineCode>{`. It’s useless. What
if we could just get rid of these two empty values?`}</p>
    <p>{`Let’s constrain the generic parameter. We’ll have to say goodbye to the
fp-ts HKT, but let’s just try for educational purposes.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "ts"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Nothing `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`null`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`undefined`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` NotNothing `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`number`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`boolean`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`bigint`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`symbol`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Function`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Date`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Error`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`RegExp`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` Maybe<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`extends`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`NotNothing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`> `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`|`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Nothing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span></code></pre>
    <p>{`Let’s just paste `}<inlineCode parentName="p">{`extends NotNothing`}</inlineCode>{` into every function that’s now glowing
red.`}</p>

    <img src={imgSrc} alt="A type error that defends us from the counterexample." />
    <p>{`Our `}<inlineCode parentName="p">{`Maybe`}</inlineCode>{` is not an endofunctor in TypeScript, but it is a mapping between
`}<em parentName="p">{`non-nullable TypeScript`}</em>{` to TypeScript! Yeah! It is a functor then! `}<strong parentName="p">{`Just
not an useful one.`}</strong></p>
    <h3 {...{
      "id": "outline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outline",
        "aria-label": "outline permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Outline`}</h3>
    <ul>
      <li parentName="ul">{`who am I`}</li>
      <li parentName="ul">{`wtf is Option`}</li>
      <li parentName="ul">{`problems with Option in fp-ts`}<ul parentName="li">
          <li parentName="ul">{`small problems`}<ul parentName="li">
              <li parentName="ul">{`fp-ts.Option.None is truthy`}</li>
              <li parentName="ul">{`incompatible with TS syntactic sugar optional chaining `}<inlineCode parentName="li">{`(.?)`}</inlineCode>{` and
nullish coalescing `}<inlineCode parentName="li">{`(??)`}</inlineCode></li>
            </ul></li>
          <li parentName="ul">{`big problems (mentioned in Maybe Not)`}<ul parentName="li">
              <li parentName="ul">{`relaxing a requirement should be a compatible change`}</li>
              <li parentName="ul">{`strengthening a promise should be a compatible change`}</li>
            </ul></li>
        </ul></li>
      <li parentName="ul">{`a simple solution`}<pre parentName="li" {...{
          "className": "night-owl-no-italics vscode-highlight",
          "data-language": "ts"
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk3"
              }}>{`type`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` Maybe<`}</span><span parentName="span" {...{
                "className": "mtk12"
              }}>{`T`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`> `}</span><span parentName="span" {...{
                "className": "mtk3"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mtk12"
              }}>{`T`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mtk9"
              }}>{`|`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mtk5"
              }}>{`null`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mtk9"
              }}>{`|`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mtk5"
              }}>{`undefined`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`;`}</span></span></code></pre></li>
      <li parentName="ul">{`implementing a fp-ts typeclass instance to use instead of Option`}<ul parentName="li">
          <li parentName="ul">{`rad `}<a parentName="li" {...{
              "href": "https://github.com/pomber/code-surfer"
            }}>{`codesurfer`}</a>
            <a parentName="li" {...{
              "href": "https://maybe-ts.now.sh/10"
            }}>{`slides`}</a></li>
        </ul></li>
      <li parentName="ul">{`problems solved`}<ul parentName="li">
          <li parentName="ul">{`✔ relaxing a requirement is a compatible change`}</li>
          <li parentName="ul">{`✔ strengthening a promise is a compatible change`}</li>
          <li parentName="ul">{`✔ it just works™ with optional chaining and nullish coalescing operators`}</li>
          <li parentName="ul">{`✔ Nothing is properly empty value `}<inlineCode parentName="li">{`(Nothing == null) === true`}</inlineCode></li>
        </ul></li>
      <li parentName="ul">{`when would you prefer Option?`}<ul parentName="li">
          <li parentName="ul">{`strictNullChecks: false`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`null`}</inlineCode>{` (or `}<inlineCode parentName="li">{`undefined`}</inlineCode>{`) is an important part of T`}</li>
        </ul></li>
    </ul>
    <h3 {...{
      "id": "more-links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#more-links",
        "aria-label": "more links permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`More links`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/swift/optional"
        }}>{`Optional in Swift`}</a>{`
— look how familiar it is`}</li>
    </ul>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      